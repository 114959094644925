.swiper-container {
  padding: 0 20px;
  position: relative;
  max-width: 1400px;
  margin: auto;
}

.swiper-container button {
  display: flex;
  position: absolute;
  z-index: 2;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  border: 1px solid #898989;
  background: #fff;
  cursor: pointer;
}

.swiper-container .prev {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.swiper-container .next {
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 800px) {
  .swiper-container {
    max-width: auto;
  }

  .swiper-container button {
    display: none;
  }
}
