.animationContainer {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  transform: none;
  gap: 20px;
}

.animationContainer .imageContainer {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll 85.7125s linear 0s infinite;
  animation-play-state: running;
  animation-delay: 0s;
}

.animationContainer .imageContainer.normal {
  animation-direction: normal;
}

.animationContainer .imageContainer.reverse {
  animation-direction: reverse;
}

.animationContainer .imageContainer .imageChildContainer {
  flex: 0 0 auto;
  display: flex;
  min-width: auto;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.animationContainer .imageContainer .imageChildContainer .imageChild {
  transform: none;
  border-radius: 5px;
  border: 1px solid var(--sub-gray-300, #dcdcdc);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.animationContainer .imageContainer .imageChildContainer .imageChild img {
  height: 88px;
  width: auto;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
