@font-face {
  font-family: "TmonMonsori";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/TmonMonsori.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url("https://webfontworld.github.io/sunn/SUIT.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");
#root {
  overflow-x: hidden;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
p {
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.display-flex {
  display: flex;
}

.mr-7 {
  margin-right: 7px;
}

#order_main .swiper-pagination {
  bottom: 40px;
}
/* order section */
@media (max-width: 900px) {
  #order_main .swiper-pagination {
    bottom: 55px;
  }
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: #ddd;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #c90040;
}

#order_main .swiper-wrapper {
  align-items: center;
}

.text-center {
  text-align: center;
}

.Force_wrap .swiper .swiper-wrapper {
  align-items: center;
}
