.container {
  max-width: 1200px;
  margin: auto;
}

.box {
  background-color: #f2f3f6;
  padding: 30px;
  border-radius: 50px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  font-family: "Noto Sans KR", sans-serif;
}
@media (max-width: 960px) {
  .box {
    padding: 20px;
  }
}

.box .swiper-pagination-bullet-active {
  background-color: #c90040;
}

.list {
  list-style: disc;
  padding-left: 30px;
  color: #222;
  /* font-family: "Noto Sans KR", sans-serif; */
}

.gridContainer {
  display: grid;
  grid-template-areas:
    "picture price1 price1"
    "picture price2 price2";
  gap: 10px;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.picture {
  grid-area: picture;
  background-image: url(../assets/images/background/price_img.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.price1 {
  grid-area: price1;
  padding: 20px 30px;
  border-radius: 30px;
  background-color: #c90040;
  color: #fff;
}
.price2 {
  grid-area: price2;
  padding: 20px 30px;
  border-radius: 30px;
  border: solid 1px #c90040;
  color: #c90040;
}

.divider {
  height: 1px;
  margin: 19.5px 0;
  background-color: #ddda;
}

.titlePrice {
  font-size: 40px;
}
.titleCaption {
  font-size: 17px;
  color: rgb(170, 170, 170);
}
.title {
  font-size: 30px;
  font-weight: bold;
}
.salesPrice {
  font-size: 17px;
  color: #ddd;
}
.subTitle {
  font-size: 26px;
  font-weight: bold;
}
.description {
  font-size: 18px;
  margin-top: 30px;
}
.gridRow {
  display: flex;
  /* align-items: baseline; */
  gap: 30px;
}
.color222 {
  color: #222222;
}

.mobileGridRow {
  display: grid;
  text-align: center;
  gap: 10px;
}

.mobileGridRow > p:last-child {
  margin-top: 8px;
}

.forceSwiper {
  padding-bottom: 35px;
}
