.Popup .Mail_Wrap div input.error,
.Popup .Mail_Wrap div select.error,
.Popup .Mail_Wrap div textarea.error {
  border-color: red;
  border-width: 2px;
}

.PopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
/* .PopupContainer {
  align-items: center;
  background-color: rgba(0,0,0,.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
} */

.Popup {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  height: auto;
  padding: 4% 44px;
  position: relative;
  text-align: center;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.Popup > div {
  height: 700px;
  overflow-y: scroll;
  padding: 0 50px;
}
/* 스크롤바의 폭 너비 */
.Popup > div::-webkit-scrollbar {
  width: 10px;
}

.Popup > div::-webkit-scrollbar-thumb {
  background: rgba(220, 20, 60); /* 스크롤바 색상 */
  border-radius: 10px; /* 스크롤바 둥근 테두리 */
}

.Popup > div::-webkit-scrollbar-track {
  background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
}
.Popup h2 {
  color: #222;
  font-family: Noto Sans KR, sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;
}

.Popup p {
  color: #666;
  font-size: 18px;
  /* margin-bottom: 10px; */
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup > div > span {
  margin: 10px 0;
  display: block;
  font-size: 18px;
  color: #c90040;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}

.Popup img {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 5%;
  -webkit-transform: translate(-5%, -5%);
  transform: translate(-5%, -5%);
}

.Popup img:hover {
  filter: brightness(0.5);
}
.Popup .Mail_Wrap {
  width: fit-content;
  margin: 0 auto;
}
.Popup .Mail_Wrap div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.Popup .Mail_Wrap div:nth-child(5) textarea {
  height: 145px;
  resize: none;
}
.Popup .Mail_Wrap div:nth-child(5) {
  margin-top: 20px;
}
.Popup .Mail_Wrap div label {
  color: #222;
  font-size: 18px;
  text-align: start;
  margin-right: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup .Mail_Wrap div input {
  padding: 13px 20px 10px;
  border: 1px solid#666;
  border-radius: 10px;
}

.Popup .Mail_Wrap div select,
.Popup .Mail_Wrap div textarea {
  padding: 13px 20px;
  border: 1px solid#666;
  border-radius: 10px;
}
.Popup .Mail_Wrap div input,
.Popup .Mail_Wrap div textarea,
.Popup button {
  width: 350px;
}
.Popup .Mail_Wrap div select {
  width: 390px;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  color: #666;
}
.Popup .Mail_Wrap div input::placeholder {
  color: #aaa;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  padding-left: 5px;
}

/* 버튼 */
.Popup button {
  padding: 20px 120px;
  border: none;
  border-radius: 10px;
  background-color: #c90040;
  color: #fff;
  margin-left: 50px;
  cursor: pointer;
  margin-top: 20px;
}
.Popup button span {
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup h3 {
  color: #c90040;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
/* 테블릿 */
@media (max-width: 1280px) {
  .Popup img {
    top: 7%;
    right: 10%;
    transform: translate(-10%, 0%);
  }
}
/* 모바일 */
@media (max-width: 768px) {
  .Popup {
    padding: 0;
    width: 100%;
  }
  .Popup > div {
    padding: 50px 0;
    height: auto;
    overflow-y: unset;
  }
  .Popup .Mail_Wrap div,
  .Popup .Mail_Wrap div input,
  .Popup .Mail_Wrap div label,
  .Popup .Mail_Wrap div textarea,
  .Popup button {
    display: block;
  }
  .Popup .Mail_Wrap div input,
  .Popup .Mail_Wrap div select,
  .Popup .Mail_Wrap div textarea {
    padding: 10px 20px;
    width: 260px;
    margin: 0 auto;
  }
  .Popup .Mail_Wrap div input {
    height: 25px;
    padding: 10px 20px 8px;
  }
  .Popup .Mail_Wrap div input::placeholder {
    font-size: 1em;
  }
  .Popup .Mail_Wrap div select {
    font-size: 0.8em;
  }
  .Popup button {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    margin-top: 20px;
  }
  .PopupContainer {
    display: block;
    overflow: scroll;
    padding: 20px 0;
  }

  .Popup h3 {
    display: block;
    margin: 10px 0;
    color: #c90040;
    font-weight: bold;
    font-size: 18px;
  }
  .Popup h2 {
    font-size: 26px;
    margin-bottom: 0;
  }
  .Popup p,
  .Popup > span {
    font-size: 16px;
  }
  .Popup .Mail_Wrap div:nth-child(5) textarea {
    height: 80px;
    overflow: scroll;
  }
  .Popup .Mail_Wrap div label {
    margin-bottom: 10px;
  }
  .Popup .Mail_Wrap div select {
    width: 100%;
  }
}
