.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1101px;
}

.swiperProgressBar {
  display: none;
  top: auto !important;
  bottom: 0;
  border-radius: 10px;
  overflow: hidden;
}

.swiperProgressBar span {
  background: #c90040 !important;
}

.swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 480px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .swiper {
    max-width: 323px;
  }

  .swiper .swiper-slide {
    width: 217px;
    height: 274px;
  }

  .swiperProgressBar {
    display: inherit;
  }
}
