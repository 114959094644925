/* Header */
#Header {
  padding: 10px 0px;
  width: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 99;
}
#Header .Header_Wrap {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#Header .Header_Wrap img {
  width: 300px;
  height: 100px;
  object-fit: cover;
}

#Header .Header_Wrap .Header_contents {
  display: flex;
  align-items: center;
}
#Header .Header_Wrap .Header_contents span {
  font-family: "SUIT";
  font-size: 1.8em;
  font-weight: 700;
}
#Header .Header_Wrap .Header_contents .RequestBtn {
  margin-left: 30px;
}
#Header .Header_Wrap .Header_contents .RequestBtn button {
  background-color: #c90040;
  color: #fff;
  border: none;
  border-radius: 39px;
  padding: 10px 20px 10px;
  cursor: pointer;
}
#Header .Header_Wrap .Header_contents .RequestBtn button span {
  font-family: "SUIT";
  font-size: 1.8em;
  font-weight: 700;
}
/* Swiper */
.Main_Swiper .swiper-pagination-bullet-active {
  width: 20px;
  border-radius: 7px;
  background-color: #c90040;
  transition: width 0.3s ease-in-out;
}
.Main_bg .swiper {
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.Main_bg .swiper .swiper-pagination {
  bottom: 60px !important;
}
.Main_bg .swiper .swiper-button-prev {
  left: 5%;
}
.Main_bg .swiper .swiper-button-next {
  right: 5%;
}
.Main_bg .swiper .swiper-slide:nth-child(1) img,
.Main_bg .swiper .swiper-slide:nth-child(3) img {
  border-radius: 50%;
  /* padding-right: 5%; */
}
.Main_bg .swiper .swiper-slide:nth-child(2) img,
.Main_bg .swiper .swiper-slide:nth-child(4) img {
  border-radius: 50%;
  /* padding-left: 5%; */
}
/* Main 배너쪽 */
.Main_bg {
  background: #fff;
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  position: relative;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex .small_title {
  font-size: 1.8em;
  font-family: "SUIT";
  font-weight: 500;
}
.flex .big_title {
  font-size: 5em;
  font-weight: 900;
  font-family: "SUIT";
  color: #444;
}
.Banner_container_01 > div > h3 {
  margin: 0;
  margin-bottom: 100px;
  font-size: 2.3rem;
  color: #c90040;
}
/* .Main_bg div.swiper-slide:nth-child(1),
.Main_bg div.swiper-slide:nth-child(3) */
.Banner_container_01,
.Banner_container_03 {
  background: url(../images/background/Main_bg01.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position-x: 15.8%;
  width: 100%;
}
/* .Main_bg div.swiper-slide:nth-child(2),
.Main_bg div.swiper-slide:nth-child(4) */
.Banner_container_02,
.Banner_container_04 {
  background: url(../images/background/Main_bg02.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  background-position-x: 15.8%;
  width: 100%;
}
.Banner_container_01 > div {
  margin-right: 5%;
}
.Banner_container_03 > div {
  margin-right: 10%;
}
.Banner_container_02 > div {
  margin-left: 5%;
}
.Banner_container_04 > div {
  margin-left: 9%;
}
/* Banner_container_01 */
.Banner_container_01 > div > .banner_image01 {
  width: 600px;
  height: auto;
}
#Request {
  z-index: 999;
  position: absolute;
  /* background: url('../images/icon/Request.png'); */
  bottom: 20px;
  right: 280px;
}
#Request img {
  cursor: pointer;
}
#Request .Request_wrap {
  position: absolute;
  font-size: 28px;
  color: #fff;
  font-weight: bold;
  left: 30%;
  top: 70%;
  transform: translate(-50%, -40%);
  cursor: pointer;
}

#Request .Request_wrap span {
  font-family: "Noto Sans KR", sans-serif;
}
@media (max-width: 1400px) {
  .Main_bg {
    height: 640px;
    padding-top: 123px;
  }
  .Main_bg .swiper {
    top: unset;
    left: unset;
    transform: unset;
    height: 100%;
  }
  .Banner_container_01 > div > h3 {
    margin-bottom: 10%;
  }
  .Banner_container_01,
  .Banner_container_02,
  .Banner_container_03,
  .Banner_container_04 {
    background-size: 100%;
    height: auto;
    padding: 100px 0;
  }
  .Main_bg .swiper .swiper-slide img {
    width: 35%;
  }
  .Banner_container_03 > div {
    margin-right: 8%;
  }
}

/* 테블릿  가로*/
@media (max-width: 1280px) {
  #Header .Header_Wrap {
    margin-right: 50px;
  }
  .flex .big_title {
    font-size: 4em;
    margin-bottom: 20px;
  }
  .flex .small_title {
    font-size: 1.5em;
  }
  .Banner_container_01 > div {
    /* margin-right: 0%; */
  }
  .Banner_container_03 > div {
    margin-right: 5%;
  }
  .Banner_container_02 > div,
  .Banner_container_04 > div {
    margin-left: 5%;
  }
  .Banner_container_01 > div > h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  /* .Banner_container_01,
  .Banner_container_02,
  .Banner_container_03,
  .Banner_container_04 {
    height: 480px;
    background-size: 100%;
  } */

  #root > div.Main_bg > div > div.swiper-wrapper .swiper-slide > div > img {
    width: 35%;
  }
  /* .Main_bg {
    height: 100%;
    padding-top: 0;
    margin-top: 125px;
  }
  .Main_bg .swiper {
    height: 100%;
    width: 100%;
    top: unset;
    left: unset;
    transform: unset;
  } */
  .Main_bg .swiper .swiper-button-prev {
    left: 3%;
  }
  .Main_bg .swiper .swiper-button-next {
    right: 3%;
  }
}
/* 테블릿 - 세로 */
@media (max-width: 1024px) {
  .Main_bg {
    height: 480px;
    padding-top: 123px;
  }
  .Main_bg .swiper {
    width: 100%;
    height: 100%;
  }
  .Banner_container_02 > div,
  .Banner_container_04 > div {
    margin-left: 6%;
  }
  .Banner_container_01,
  .Banner_container_02,
  .Banner_container_03,
  .Banner_container_04 {
    padding: 7% 0;
    background-size: contain;
  }
  .Main_bg .swiper .swiper-pagination {
    bottom: 30px !important;
  }
}
@media (max-width: 1400px) and (max-height: 884px) {
  .Banner_container_01,
  .Banner_container_02,
  .Banner_container_03,
  .Banner_container_04 {
    background-size: cover;
    height: 100%;
    padding: 0;
  }
}
/* 모바일 */
@media (max-width: 768px) {
  #Header {
    /* padding: 10px 20px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }
  /* Header 추가 S */
  #Header .Header_Wrap {
    width: 100%;
    margin: 0 10px;
  }
  #Header .Header_Wrap img {
    width: 155px;
    height: 60px;
  }
  #Header .Header_Wrap .Header_contents span {
    font-size: 0.5em;
  }
  #Header .Header_Wrap .Header_contents .RequestBtn {
    margin: 0;
    margin-left: 5px;
  }
  #Header .Header_Wrap .Header_contents .RequestBtn button {
    padding: 10px 20px;
  }
  #Header .Header_Wrap .Header_contents .RequestBtn button span {
    font-size: 0.9em;
  }
  /* Header 추가 E */
  #Header .Mob_RequestBtn {
    margin-top: 5px;
  }
  #Header .Mob_RequestBtn button {
    background-color: #c90040;
    border-radius: 25px;
    border: none;
    padding: 8px 14px 10px;
  }
  #Header .Mob_RequestBtn button span {
    font-size: 15px;
    color: #fff;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;
  }
  /* Moblie Main */
  .Main_bg {
    margin-top: 80px;
    height: 100%;
    text-align: center;
    padding: 80px 0 0px;
    background: url("../images/background/Mob_MainBg.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Main_bg .swiper {
    top: unset;
    left: unset;
    transform: unset;
    height: 500px;
  }
  .Mob_Banner_container .Text_Wrap {
    text-align: center;
  }
  .Main_bg .swiper .swiper-button-prev,
  .Main_bg .swiper .swiper-button-next {
    display: none;
  }
  .Main_bg .swiper .swiper-pagination {
    bottom: 30px !important;
  }
  .Mob_Banner_container .Text_Wrap .small_title {
    font-size: 18px;
    font-weight: 500;
    color: #222;
    font-family: "SUIT";
  }
  .Mob_Banner_container .Text_Wrap .big_title {
    color: #444;
    font-family: "SUIT";
    font-weight: 900;
    font-size: 32px;
  }
  .Mob_Banner_container > img {
    width: 300px !important;
    height: 300px;
  }
  .PopupOpen {
    z-index: -9;
    /* 원하는 추가 스타일 설정 */
  }
}
