.installation_section {
  /* width: 100%; */
  margin: 0 auto;
  background: url(../images/background/body_BG-1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 17.1%;
  padding: 100px 0 !important;
}
@media (max-width: 767px) {
  .installation_section {
    background: none;
  }
  .slick_image {
    margin: 20px 10% !important;
  }
}

/* .installation_section {
  padding-top: 100px !important;
} */

.slick-slider {
  width: 80%;
  margin: 0 auto;
}

.custom-slider div > img:not(.slick-arrow > img) {
  width: 90%;
  height: 300px;
  object-fit: cover;
  margin: 0 auto;
}
#Installation .title {
  text-align: center;
  color: #c90040;
  font-size: 55px;
  font-family: "SUIT";
  font-weight: 500;
  margin: 0;
}
#Installation > p:first-child {
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: "TmonMonsori";
  text-align: center;
  /* margin-top: 100px; */
}
#Installation > p:nth-child(3) {
  text-align: center;
  display: block;
  font-size: 20px;
  color: #222;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  margin: 30px 0 60px;
}
@media (max-width: 769px) {
  .custom-slider div > img {
    height: auto;
  }
  #Installation .title {
    font-size: 32px;
  }
  #Installation > p:first-child {
    color: #aaa;
    font-size: 16px;
  }
  #Installation > p:nth-child(3) {
    font-size: 16px;
  }
}

.installation_section .slick-arrow::after {
  content: "";
}
.installation_section .slick-arrow::before {
  content: "";
}

.slick_image {
  height: 380px;
  margin: 20px;
  border-radius: 30px;
}

.slick_image.image1 {
  background: url(../images/install/Install_Image01.jpeg);
  background-position: center;
  background-size: cover;
}

.slick_image.image2 {
  background: url(../images/install/Install_Image02.jpeg);
  background-position: center;
  background-size: cover;
}

.slick_image.image3 {
  background: url(../images/install/Install_Image03.jpeg);
  background-position: center;
  background-size: cover;
}

.slick_image.image4 {
  background: url(../images/install/Install_Image04.jpeg);
  background-position: center;
  background-size: cover;
}
