.orderSection {
  /* width: 100%;
  margin: 0 auto;
  background: url(../../assets/images/background/body_BG-1.png);
  background-repeat: no-repeat;
  background-size: 16.6%; */
  padding: 200px 0 0 0 !important;
}

.orderMain {
  display: flex;
  gap: 20px;
  max-width: 1400px;
  margin: auto;
}

.paginationWrapper {
  flex-shrink: 0;
  min-width: 360px;
  border-radius: 30px;
  background-color: #02142c;
}

.paginationWrapper ul {
  text-align: center;
  padding: 20px 30px;
}

.paginationWrapper li {
  color: #fff;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  padding: 18px 50px;
}

.paginationWrapper li:not(.item) {
  padding: 0;
  cursor: auto;
  padding: 0;
  margin-top: 7px;
}

.item.on {
  border-radius: 39px;
  border: solid 3px #c90040;
  background-color: #c90040;
}

.swiperWrapper {
  flex-grow: 1;
  width: 0;
  align-self: center;
}

.swiperWrapper .swiper-pagination-bullet-active {
  background-color: #c90040;
}

.mobileTab {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  text-align: center;
}

.mobileItem {
  flex-basis: 33.3%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  font-size: 20px;
  transition: unset;
  color: #666;
  background-color: #f2f3f6;
  /* padding: 20px 0; */
  height: 85px;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  word-break: keep-all;
  flex: 50%;
}

.mobileItem:nth-child(n + 4) {
  flex-basis: 50%;
}

.mobileItem.on {
  background: #c90040;
  color: #fff;
}

@media (max-width: 900px) {
  .orderMain {
    display: block;
    gap: 0;
    background: linear-gradient(to top, #02142c 70%, #ffffff00 30%);
  }
  .swiperWrapper {
    width: 100%;
  }
}

.orderSwiper {
  align-items: center;
}

.orderSwiper .swiper-pagination-bullet-active {
  background-color: #c90040;
}
