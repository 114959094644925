.Popup02 .Mail_Wrap div input.error,
.Popup02 .Mail_Wrap div select.error,
.Popup02 .Mail_Wrap div textarea.error {
  border-color: red;
  border-width: 2px;
}

.PopupContainer02 {
  position: relative;
  width: 100%;
  height: 100vh;
}

.Popup02 {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: -moz-fit-content;
  background-color: #fff;
  margin: 40px 0;
  padding: 44px 0;
}
.Popup02 > div {
  /* overflow-y: scroll; */
  padding: 0 50px;
  height: auto;
}
/* 스크롤바의 폭 너비 */
.Popup02 > div::-webkit-scrollbar {
  width: 10px;
}

.Popup02 > div::-webkit-scrollbar-thumb {
  background: rgba(220, 20, 60); /* 스크롤바 색상 */
  border-radius: 10px; /* 스크롤바 둥근 테두리 */
}

.Popup02 > div::-webkit-scrollbar-track {
  background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
}
.Popup02 h2 {
  color: #222;
  font-size: 36px;
  margin-bottom: 10px;
  margin-top: 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
}

.Popup02 p {
  color: #666;
  font-size: 18px;
  /* margin-bottom: 10px; */
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup02 > div > span {
  margin: 10px 0;
  display: block;
  font-size: 18px;
  color: #c90040;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}

.Popup02 img {
  position: absolute;
  cursor: pointer;
  top: 5%;
  right: 5%;
  transform: translate(-5%, -5%);
}

.Popup02 img:hover {
  filter: brightness(0.5);
}
.Popup02 .Mail_Wrap {
  width: fit-content;
  margin: 0 auto;
}
.Popup02 .Mail_Wrap div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.Popup02 .Mail_Wrap div:nth-child(5) textarea {
  height: 145px;
  resize: none;
}
.Popup02 .Mail_Wrap div:nth-child(5) {
  margin-top: 20px;
}
.Popup02 .Mail_Wrap div label {
  color: #222;
  font-size: 18px;
  text-align: start;
  margin-right: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup02 .Mail_Wrap div input {
  padding: 13px 20px 10px;
  border: 1px solid#666;
  border-radius: 10px;
}

.Popup02 .Mail_Wrap div select,
.Popup02 .Mail_Wrap div textarea {
  padding: 13px 20px;
  border: 1px solid#666;
  border-radius: 10px;
}
.Popup02 .Mail_Wrap div input,
.Popup02 .Mail_Wrap div textarea,
.Popup02 button {
  width: 350px;
}
.Popup02 .Mail_Wrap div select {
  width: 390px;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  color: #666;
}
.Popup02 .Mail_Wrap div input::placeholder {
  color: #aaa;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  padding-left: 5px;
}

/* 버튼 */
.Popup02 button {
  padding: 20px 120px;
  border: none;
  border-radius: 10px;
  background-color: #c90040;
  color: #fff;
  margin-left: 50px;
  cursor: pointer;
  margin-top: 20px;
}
.Popup02 button span {
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
}
.Popup02 h3 {
  color: #c90040;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  /* margin-left: 60px; */
  font-family: 'Noto Sans KR', sans-serif;
}
/* 테블릿 */
@media (max-width: 1280px) {
  .Popup02 img {
    top: 7%;
    right: 10%;
    transform: translate(-10%, 0%);
  }
}
/* 모바일 */
@media (max-width: 768px) {
  .Popup02 {
    width: 100%;
    height: 100%;
    padding: 0;
    box-shadow: unset;
    border-radius: unset;
  }
  .Popup02 > div {
    padding: 0 0 50px;
    height: auto;
    overflow-y: unset;
  }
  .Popup02 .Mail_Wrap div,
  .Popup02 .Mail_Wrap div input,
  .Popup02 .Mail_Wrap div label,
  .Popup02 .Mail_Wrap div textarea,
  .Popup02 button {
    display: block;
  }
  .Popup02 .Mail_Wrap div input,
  .Popup02 .Mail_Wrap div select,
  .Popup02 .Mail_Wrap div textarea {
    padding: 10px 20px;
    width: 260px;
    margin: 0 auto;
  }
  .Popup02 .Mail_Wrap div input {
    height: 25px;
    padding: 10px 20px 8px;
  }
  /* .Popup02 .Mail_Wrap div input::placeholder {
    font-size: 1em;
  }
  .Popup02 .Mail_Wrap div select {
    font-size: 0.8em;
  } */
  .Popup02 button {
    width: 100%;
    margin: 0;
    padding: 10px 0;
    margin-top: 20px;
  }
  .Popup02Container {
    display: block;
    /* overflow: scroll; */
    /* padding: 20px 0; */
  }

  .Popup02 h3 {
    display: block;
    margin: 10px 0;
    color: #c90040;
    font-weight: bold;
    font-size: 18px;
  }
  .Popup02 h2 {
    font-size: 26px;
    margin-bottom: 0;
  }
  .Popup02 p,
  .Popup02 > span {
    font-size: 16px;
  }
  .Popup02 .Mail_Wrap div:nth-child(5) textarea {
    height: 80px;
    overflow: scroll;
  }
  .Popup02 .Mail_Wrap div label {
    margin-bottom: 10px;
  }
  .Popup02 .Mail_Wrap div select {
    width: 100%;
  }
}
