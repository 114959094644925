.cardWrapper.active {
  background: #686868;
  border: 4px solid #dcdcdc;
  box-shadow: 0px 2px 6.9px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6.9px 3px rgba(0, 0, 0, 0.25);
}

.cardWrapper {
  border-radius: 24px;
  width: 414px;
  height: 522px;
  stroke-width: 1px;
  stroke: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: -5px 0px 8.5px 10px rgba(0, 0, 0, 0.15);
}
.cardWrapper img {
  flex-shrink: 0;
  width: 100%;
  min-height: 100%;
}

@media (max-width: 800px) {
  .cardWrapper.active {
    border: 2px solid #dcdcdc;
    box-shadow: 0px 1px 9.5px 0px rgba(0, 0, 0, 0.2);
  }

  .cardWrapper {
    border-radius: 15px;
    width: 217px;
    height: 274px;
    background: linear-gradient(89deg, rgba(228, 228, 228, 0.2) 1.16%, rgba(85, 85, 85, 0.6) 95%);
  }
}

/* .isBothSide {
  filter: blur(2.5px);
}

.isBothEnd {
  filter: blur(10px);
} */
