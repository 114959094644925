.container {
  /* max-width: 1200px; */
  margin: auto;
  padding: 0 24px;
}

.commonTitle {
  color: #aaa;
  font-size: 25px;
  font-weight: bold;
  font-family: "TmonMonsori";
  text-align: center;
}

.title {
  text-align: center;
  font-size: 55px;
  font-family: "SUIT";
  font-weight: 500;
  color: #c90040;
  margin: 15px 0 30px;
}

.subTitle {
  text-align: center;
  display: block;
  font-size: 20px;
  color: #222;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  margin-bottom: 60px;
}

@media (max-width: 769px) {
  .commonTitle {
    font-size: 16px;
  }
  .title {
    font-size: 32px;
    margin-top: 3px;
  }
}
